<template>
  <div class="swap-index">
    <subnav currPage="tokenSwap" showPrevious></subnav>
    <v-container class="my-5 pa-sm-7">
      <!-- title -->
      <div class="font-w-4 d-flex justify-space-between align-center mb-2 mb-sm-15">
        <span class="rem-0 rem-sm-3 primary--text">SWAP</span>
        <!--<div class="rem-0 rem-sm-3 swap-text d-flex align-center">
          TBT SWAP
          <img class="ml-1" src="@/assets/img/icon-to-swap.svg">
        </div>-->
      </div>

      <!-- card -->
      <div class="swap-card white rounded-lg pa-6 pa-sm-0">
        <!-- From -->
        <div class="swap-text mb-3">From</div>
        <v-form class="swap-input" ref="swapForm" lazy-validation>
          <v-text-field
            v-model="fromAmount"
            class="rounded-lg"
            outlined
            flat
            solo
            reverse
            :placeholder="swapTokens[currToken] === 'eth' ? `0 ETH` : `0.000,000,000 ${swapTokens[currToken].toUpperCase()}`"
            persistent-hint
            :hint="`Balance ${balance[swapTokens[currToken]]} ${swapTokens[currToken].toUpperCase()}`"
            :rules="[...TokenAmountRules, (v) => parseFloat(v) < balance[swapTokens[currToken]] || 'Under Balance']"
            @change="update('from')"
          >
            <template v-slot:append>
              <div class="swap-token primary white--text">
                <img :src="`${require(`@/assets/img/icon-logo-${swapTokens[currToken]}.svg`)}`" :alt="swapTokens[currToken].toUpperCase()" width="23px">
                {{ swapTokens[currToken].toUpperCase() }}
              </div>
            </template>
          </v-text-field>
          <div class="input-hint can-click" @click="fromAmount = balance[swapTokens[currToken]]">MAX</div>
        </v-form>

        <!-- convert -->
        <div class="convert-btn py-2 mt-4 mb-1 mx-auto font-w-4 rem-0 lightPrimary2--text d-flex justify-center align-center can-click" @click="convert()">
          <img src="@/assets/img/icon-convert.svg" class="mr-1">
        </div>

        <!-- To -->
        <div class="swap-text mb-3">To</div>
        <div class="swap-input">
          <v-text-field
            v-model="toAmount"
            class="rounded-lg"
            outlined
            flat
            solo
            reverse
            :placeholder="swapTokens[1 - currToken] === 'eth' ? `0 ETH` : `0.000,000,000 ${swapTokens[1 - currToken].toUpperCase()}`"
            persistent-hint
            :hint="`Balance ${balance[swapTokens[1 - currToken]]} ${swapTokens[1 - currToken].toUpperCase()}`"
            @change="update('to')"
          >
            <template v-slot:append>
              <div class="swap-token primary white--text">
                <img :src="`${require(`@/assets/img/icon-logo-${swapTokens[1 - currToken]}.svg`)}`" :alt="swapTokens[1 - currToken].toUpperCase()" width="23px">
                {{ swapTokens[1 - currToken].toUpperCase() }}
              </div>
            </template>
          </v-text-field>
          <div class="input-hint can-click" @click="toAmount = balance[swapTokens[1 - currToken]]">MAX</div>
        </div>

        <btnPill
          v-if="allowance[swapTokens[currToken]]===0 || allowance[swapTokens[currToken]]<balance[swapTokens[currToken]]"
          class="d-block mx-auto mb-2"
          btnText="approve"
          bgColor="error"
          maxWidth="180px"
          @clickBtn="approve()"
        />
        <btnPill
          v-else
          class="d-block mx-auto mb-2"
          btnText="SWAP"
          maxWidth="180px"
          @clickBtn="swap()"
        />

        <div class="font-w-4 rem-0 swap-text text-center">{{ swapTokens[currToken] === 'egt' ? '1 ETH：1200 EGT' : '1 ETH：1000 EGT' }}</div>
      </div>
    </v-container>

    <!-- exchange register dialog -->
    <v-dialog
      v-model="registerDialog"
      persistent
      max-width="538px"
    >
      <v-card
        class="primary--text px-6 py-8 pa-sm-12"
        color="white"
      >
        <div class="swap-register-content mx-auto d-flex flex-column align-center">
          <h2 class="rem-12 rem-sm-24 font-weight-bold mb-2 mb-sm-4 text-center" v-text="$t('swapRegisterTitle')" />
          <div class="rem-1 rem-sm-8 text-center" v-text="$t('swapRegisterSubtitle')" />

          <v-form class="my-11 my-sm-15 w-100" ref="registerForm" lazy-validation>
            <div class="rem-6 mb-2" v-text="$t('enterSwapRegisterAddress')" />
            <v-text-field
              class="address-input"
              v-model="registerAddress"
              :placeholder="$t('enterAddress')"
              outlined
              flat
              solo
              light
              dense
              persistent-hint
              height="46"
              :rules="accountRules"
            />
          </v-form>

          <btnPill
            class="mb-2"
            btnText="confirm"
            @clickBtn="registerSwap()"
          />
          <btnPill
            btnText="back"
            textColor="primary--text"
            bgColor="white"
            outlineColor="primary"
            @clickBtn="closeRegisterDialog()"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import btnPill from '@/components/btn-pill.vue'
import subnav from '@/components/subnav.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      swapTokens: ['egt', 'eth'],
      currToken: 0,
      balance: {
        egt: 0,
        eth: 0,
      },
      allowance: {
        egt: 0,
        eth: 0,
      },
      fromAmount: null,
      toAmount: null,
      ethPrice: 0,
      timer: null,
      swapIsMember: false,
      registerDialog: false,
      registerAddress: ''
    }
  },
  components: {
    btnPill,
    subnav
  },
  methods: {
    convert() {
      this.currToken = 1 - this.currToken
      this.fromAmount = null
      this.toAmount = null
    },
    update(type) {
      if (type === 'from') {
        let price = this.swapTokens[this.currToken] === 'eth' ? 1000 : 1200
        this.toAmount = this.swapTokens[this.currToken] === 'eth' ? parseFloat(this.fromAmount) * price : (parseFloat(this.fromAmount) / price).toFixed(5)
      } else {
        let price = this.swapTokens[this.currToken] === 'eth' ? 1000 : 1200
        this.fromAmount = this.swapTokens[this.currToken] === 'eth' ? (parseFloat(this.toAmount) / price).toFixed(5) : parseFloat(this.toAmount) * price
      }
    },
    async getBalance() {
      this.balance.egt = await this.$token.egt.getBalance()
      this.balance.eth = await this.$token.eth.getBalance()
    },
    async getAllowance() {
      this.allowance.egt = await this.$token.egt.getAllowance(this.$store.state.exchangeAddress)
      this.allowance.eth = await this.$token.eth.getAllowance(this.$store.state.exchangeAddress)
    },
    async approve() {
      let token = this.swapTokens[this.currToken]
      if (this.$store.state.chainId) {
        let result = await this.$token[token].approve(this.$store.state.exchangeAddress)
        if (result.txHash) {
          this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitApprove')})
          this.timer = window.setInterval(async () => {
            await this.getAllowance()
            if (this.allowance[token] >= this.balance[token]) {
              window.clearInterval(this.timer)
              this.$store.commit('updateLoading', {isShow: false, text: ''})
            }
          }, 1000)
        } else if (result.code === 4001) {
          this.$toasted.error(this.$t('userRefuse'))
        }
      } else {
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async swap() {
      if (this.swapTokens[this.currToken] === 'eth' && !this.swapIsMember) {
        this.registerDialog = true
        return
      }
      if (!this.$refs.swapForm.validate()) { return }

      if (!this.$store.state.chainId) {
        this.$toasted.error(this.$t('changeMainnet'))
        return
      }

      let result
      if (this.swapTokens[this.currToken] === 'eth') {
        result = await this.$exchange.buy(this.fromAmount)
      } else {
        result = await this.$exchange.sell(this.fromAmount)
      }
      
      if (result.txHash) {
        await this.getBalance()
        this.$toasted.show(this.$t('txSend'))
        this.fromAmount = null
        this.toAmount = null
        this.$refs.swapForm.reset()
      } else if (result.code === 4001) {
        this.$toasted.error(this.$t('userRefuse'))
      }
    },
    async getSwapIsMember () {
      this.swapIsMember = await this.$exchange.isMember(this.$store.state.account)
    },
    async registerSwap () {
      if (this.swapIsMember) { return }
      if (!this.$refs.registerForm.validate()) { return }

      if (!this.$store.state.chainId) {
        this.$toasted.error(this.$t('changeMainnet'))
        return
      }

      const result = await this.$exchange.register(this.registerAddress)
      if (result.txHash) {
        this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitActivate')})
        this.timer = window.setInterval(async () => {
          await this.getSwapIsMember()
          if (this.swapIsMember) {
            window.clearInterval(this.timer)
            this.$store.commit('updateLoading', {isShow: false, text: ''})
            this.closeRegisterDialog()
          }
        }, 1000)
      } else if (result.code === 4001) {
        this.$toasted.error(this.$t('userRefuse'))
      }
    },
    closeRegisterDialog () {
      this.registerAddress = ''
      this.$refs.registerForm.resetValidation()
      this.registerDialog = false
    }
  },
  async mounted(){
    let result = await this.$store.dispatch('getEthPrice')
    if (result.status === 200) {
      this.ethPrice = result.data
    }

    this.getBalance()
    this.getAllowance()
    await this.getSwapIsMember()
  }
}
</script>
<style lang="scss">
.swap-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
  }
  .swap-card {
    .swap-token {
      padding: 8px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 11px;
      }
    }
    .swap-input {
      position: relative;
      .input-hint {
        position: absolute;
        font-size: 12px;
        right: 0;
        bottom: 6px;
        font-weight: 700;
        color: var(--v-lightGrey3-base);
      }
      .v-messages {
        font-weight: 400;
        color: var(--v-lightGrey3-base);
      }
    }
    .convert-btn {
      background: #1A1C29;
      border-radius: 32px;
      width: 100px;
    }
    .swap-btn {
      width: 180px;
      border-radius: 32px;
    }
  }
  .swap-text {
    color: #838383;
  }

}
.swap-register-content {
  max-width: 370px;
}
</style>